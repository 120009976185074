import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import {until} from 'lit/directives/until';
import '@iconify/iconify';

//https://iot.hamburg.de/v1.1/Things(6)/Datastreams(14)/Observations?$orderBy=phenomenonTime%20desc&$top=1&$select=result
// https://geodienste.hamburg.de/HH_WMS_Stadtrad?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&QUERY_LAYERS=stadtrad_stationen&LAYERS=stadtrad_stationen&INFO_FORMAT=text%2Fxml&I=198&J=511&WIDTH=572&HEIGHT=572&CRS=EPSG%3A25832&STYLES=&BBOX=567748.088778032%2C5934286.755261819%2C568126.4427403876%2C5934665.109224174

const WMS_BIKE_COUNT_URL =
  'https://geodienste.hamburg.de/HH_WMS_Stadtrad?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&QUERY_LAYERS=stadtrad_stationen&LAYERS=stadtrad_stationen&INFO_FORMAT=text%2Fxml&I=198&J=511&WIDTH=572&HEIGHT=572&CRS=EPSG%3A25832&STYLES=&BBOX=567748.088778032%2C5934286.755261819%2C568126.4427403876%2C5934665.109224174';

@customElement('stadtrad-count')
export class StadtradCount extends LitElement {
  render() {
    return html`
      <div class="flex p-2 space-x-4 place-content-center object-center		">
        <div class="">
          <i class="h-6 w-6 iconify" data-icon="mdi:bike"></i>
        </div>
        <div class="text-xl">${until(this.getCount(), html`?`)}</div>
      </div>
    `;
  }

  createRenderRoot() {
    return this;
  }

  async getCount() {
    let res = await fetch(WMS_BIKE_COUNT_URL).then((res) => res.text());
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, 'text/xml');
    return xmlDoc.querySelector(
      'FeatureCollection > featureMember > stadtrad_stationen > anzahl_raeder'
    )?.innerHTML;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'stadtrad-count': StadtradCount;
  }
}
